@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: "poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

.css-1p1aorn-MuiDataGrid-root {
  border: 1px solid #e0e0e0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  background-color: white !important;
}

/* .MuiDataGrid-columnHeaderTitleContainer{
  background-color: white !important;
}
.MuiDataGrid-columnHeader--moving{
  background-color: white !important;

} */
 
.spinner {
  animation: rotation 2s infinite linear;
  height: 50px;
  width: 50px;
}

.icon span {
  font-size: 16px !important;
}

.bordered-table {
  border: 1px solid #E8E8E8;
}

.border-error {
  border-left: 12px solid #D32F2F !important;
}

.border-l-12 {
  border-left: 12px solid transparent !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.MuiDataGrid-columnHeaderTitl {
  font-family: 'poppins' !important;
}

@layer utilities {
  .animated-hover {
    @apply transition duration-500 transform cursor-pointer;
  }

  .animated-hover:hover {
    scale: 0.8
  }
}

.no-padding {
  padding: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.permanent-tooltip {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.bg-light-blue {
  background-color: #EBF9FC !important;
}

@media (max-width: 390px) {
  .MuiDataGrid-main {
    /* min-width: fit-content !important; */
  }

  /* .MuiDataGrid-columnHeader {
    min-width: fit-content !important;
  } */
}


@media (max-width: 768px) {
  .MuiDataGrid-main {
    /* min-width: fit-content !important; */
  }

  /* .MuiDataGrid-columnHeader {
    display: flex !important;
    min-width: auto !important;
    width: auto !important;
    max-width: 100% !important;
  } */
}

/* .navUl {
  position: absolute;
  left: 0;
  padding: 0.5rem;
  margin: 2rem;
  margin-top: 1.5rem;
  list-style: none;
  z-index: 100;
  background-color: white;
  border: 1px solid #e1e1e1;
} */

/* .navUlLi {
  float: left;
  border-top: 1px solid #fff;
  width: 100%;
  white-space: nowrap;
  z-index: 100;
} */

/* .resp-li:hover {
  background-color: rgb(0 82 107);
  color: white;
} */

.resp-li:hover {
  --tw-transform: scale(2) !important;
  transition-duration: 200ms !important;
}

@media (max-width: 390px) {
  .resp-ul {
    position: absolute;
    left: 0;
    padding: 0.5rem;
    margin: 0.8rem;
    margin-top: 1rem;
    list-style: none;
    z-index: 100;
    background-color: white;
    border: 1px solid #e1e1e1;
  }
}

@media (max-width: 768px) {
  .resp-ul {
    position: absolute;
    left: 0;
    padding: 0.5rem;
    margin: 1.5rem;
    margin-top: 1.5rem;
    list-style: none;
    z-index: 100;
    background-color: white;
    border: 1px solid #e1e1e1;
  }
}

@media (max-width: 1024px) {
  .resp-ul {
    position: absolute;
    left: 0;
    padding: 0.5rem;
    margin: 1.5rem;
    margin-top: 1.5rem;
    list-style: none;
    z-index: 100;
    background-color: white;
    border: 1px solid #e1e1e1;
  }
}

@media (min-width: 1024px) {
  .resp-ul {
    display: relative !important;
  }
}

.css-1laar4y-MuiTabs-root {
  overflow: unset !important;
}

.ql-container {
  background-color: white !important;
  border-radius: 4px;
}

.ql-tooltip {
  left: 0px !important;
  top: 25px !important;
}

.MuiDrawer-paperAnchorLeft {
  float: left;
  background: #f5f5f5;
  margin-bottom: 15px;
}

.MuiAutocomplete-popper .MuiPaper-root {
  background-color: #DEE2E3 !important;

}



.MuiTab-textColorPrimary.Mui-selected {
  color: #00526b;
  font-weight: 700 !important;
}

.MuiTab-textColorPrimary {
  color: white !important;
}

.MuiTableCell-root {
  padding: 0px;
}

.side-card {
  position: relative;
}

.side-card .side-card-overlay {
  display: none;
}

.side-card:hover .side-card-overlay {
  display: flex;
}

.side-card-overlay {
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  left: 0px;
}

.drag-enter {
  height: 40px !important;
  width: 100%;
  border: 3px dashed lightgray
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(189, 189, 189);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: rgb(199, 199, 199);
  border-radius: 20px;
}

.css-6vjctt-MuiLinearProgress-root {
  background-color: white !important;
}

.scroll-grid .MuiDataGrid-virtualScroller {
  height: 300px !important;
  overflow-x: visible;
}

.scroll-grid .MuiDataGrid-virtualScrollerRenderZone {
  height: 300px !important;
  overflow-y: auto;
  overflow-x: hidden;
  position: inherit !important;
}

.scroll-grid .MuiDataGrid-virtualScrollerContentr {
  height: 300px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Added these styles to hide the horizontal scrollbar of status cards on mobile view */
.scroll-container {
  overflow-x: auto;
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: transparent transparent;
  /* Firefox */

  /* Hide scrollbar for other browsers */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Microsoft Edge */
}

.scroll-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hover-pointer {
  &:hover {
    cursor: pointer;
  }
}

/* signed positions */
.signed-row {
  color: #4CAF50 !important;

  button {
    color: #4CAF50 !important;
  }

  label {
    color: #4CAF50 !important;
  }
}

.unsigned-date {
  .css-1gctnaj-MuiInputBase-input-MuiFilledInput-input {
    padding-top: 10px !important;
  }
}

.signed-date * {
  background-color: transparent !important;
  border: none !important;

  .css-1ukl8ad-MuiInputBase-root-MuiFilledInput-root {
    border: 1px solid #4CAF50 !important;
  }

  color: #4CAF50;

  button {
    border: none !important;
  }

  .css-1ukl8ad-MuiInputBase-root-MuiFilledInput-root.Mui-disabled {
    color: #4CAF50 !important;
  }

  .css-1gctnaj-MuiInputBase-input-MuiFilledInput-input.Mui-disabled {
    color: #4CAF50 !important;
    -webkit-text-fill-color: #4CAF50 !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.norway-text {
  position: 'relative';
  overflow: 'hidden';
  white-space: 'nowrap';
}

.scroll-text {
  display: inline-block;
  padding-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.norway-text:hover .scroll-text {
  animation: scrollText 3s linear infinite alternate;
  text-overflow: unset;
  overflow: visible;
}

@keyframes scrollText {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-50%);
  }
}

.sidebar-service-icon {
  path {
    fill: white;
  }
}
